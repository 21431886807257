import React from 'react'
import img1 from "../../assets/images/ser1.png";
import img2 from "../../assets/images/ser2.png";
import img3 from "../../assets/images/ser3.png";
import img4 from "../../assets/images/ser4.png";
const WhyChooseus = () => {
  return (
    <div>
    <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
      <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
      Why Choose Ontime Taxi Rider?
      </h4>
   
      <div className="flex flex-col gap-y-[10px] pt-4">
        <p>
          <span className=' font-semibold text-xl pl-2'>No Return Fare </span>
          Pay only for the distance you travel. We believe in transparency and value for money, which is why we don’t charge a return fare for your drop trips.
        </p>
        <p>
          <span className=' font-semibold text-xl pl-2'>Comfort and Safety </span>
          Our fleet of well-maintained vehicles is driven by experienced and courteous drivers who prioritize your comfort and safety above all.        </p>
          <p>
          <span className=' font-semibold text-xl pl-2'> Punctuality Guaranteed </span>
          As our name suggests, we are committed to being on time, every time. We understand the importance of time in your journey.  
                      </p>
                      <p>
          <span className=' font-semibold text-xl pl-2'> Pan-Tamil Nadu Service </span>
          Wherever you need to go within Tamil Nadu, Ontime Taxi Rider is ready to take you there. We cover all major cities, towns, and rural areas across the state.
                      </p>
      </div>
      <div className='text-3xl text-center mt-4'>Book Your Ride Today</div>
      <p className='mt-2'>Experience the convenience and reliability of our drop taxi service. Whether you’re traveling for business, leisure, or personal reasons, Ontime Taxi Rider is here to make your journey smooth and hassle-free.</p>
    </div>
    {/* <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
      <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
        <div className=" flex flex-col gap-y-1">
          <img src={img1} className=" w-[80px] mx-auto" />
          <p className=" text-lg md:text-xl font-semibold">
            Easy Online Booking
          </p>
          <p>Pickup and Drop on time</p>
        </div>
        <div className=" flex flex-col gap-y-1">
          <img src={img2} className=" w-[80px] mx-auto" />
          <p className=" text-lg md:text-xl font-semibold">
          Professional Drivers
          </p>
          <p>Well Trained years of experience</p>
        </div>
        <div className=" flex flex-col gap-y-1">
          <img src={img3} className=" w-[80px] mx-auto" />
          <p className=" text-lg md:text-xl font-semibold">
          Variety of Prime Brands
          </p>
          <p>Benz - BMW - Audi</p>
        </div>
        <div className=" flex flex-col gap-y-1">
          <img src={img4} className=" w-[80px] mx-auto" />
          <p className=" text-lg md:text-xl font-semibold">
          Secure Online Payment
          </p>
          <p>24 /7 Customer Support</p>
        </div>
      </div>
    </div> */}
  </div>
  )
}

export default WhyChooseus