import React from 'react'


const OurServiceComponent = ({img,heading,para}) => {
    return (
      
            <div className="flex flex-col bg-[#2A2F3C] rounded-lg p-4 px-10 justify-between gap-y-3">
                <div className="flex justify-center">
                    <img src={img} className="w-[60%]" />
                </div>
                <span className="font-bold text-2xl text-white text-center">{heading}</span>
                <p className="text-center text-white">
                    {para}
                </p>
            </div>
     

 
    )
}

export default OurServiceComponent