import React from "react";
import OurServiceComponent from "./OurServiceComponent";
import train from "../../assets/images/trainicon.png";
import aeroplane from "../../assets/images/aeroplaneicon.png";
import taxi from "../../assets/images/taxiicon.png";
import tour from "../../assets/images/tour-guide-icon.png";

const OurServices = () => {
  return (
    
    <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16" id='service'>
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
        Our Service
      </h4>
      <p className="mt-2 text-center">At Ontime Taxi Rider, we offer a wide range of drop taxi services to cater to your travel needs across Tamil Nadu. Whether you're planning a short trip or a long journey, we've got you covered.</p>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-3 my-12">
      <OurServiceComponent img={taxi} heading='One-Way Drop Taxi Services' para="Travel to your destination without worrying about the return trip. Our one-way drop taxi services ensure you only pay for the distance you travel. Enjoy a cost-effective solution for your travel needs."/>
      <OurServiceComponent img={tour} heading='Intercity Travel' para ='Need to travel from one city to another within Tamil Nadu? Our intercity travel service is perfect for you. We provide comfortable and timely transportation between cities, ensuring a smooth and hassle-free experience.' />
      <OurServiceComponent img={aeroplane} heading='Airport Transfers' para='Arriving at or departing from any major airport in Tamil Nadu? Our airport transfer service guarantees a stress-free journey to and from the airport. Our drivers monitor flight schedules to ensure timely pickups and drop-offs.'/>
      <OurServiceComponent img={train} heading='Long-Distance Travel' para='Planning a trip to a distant location? Our long-distance travel service is ideal for those seeking comfort and convenience on extended journeys. We cover all major routes across Tamil Nadu, ensuring a safe and enjoyable ride.'/>
      </div>
  </div>
  );
};

export default OurServices;
